(function($){ 
	
	
	    
	$.fn.menu=function(callback){     
        

		/*
		| ---------------------------
		| add overlay if is not
		| ---------------------------
		*/
		var overlay = $('<div class="overlay" id="overlay_menu"></div>');
		if(!$("#overlay_menu").length){
		$(overlay).appendTo('body');
		}
		
		
		
		
		/*
		| ---------------------------
		| global returned objects
		| ---------------------------
		*/
        return this.each(function(){
			
			//On stocke notre élément dans une variable par commodité
			var element=$(this);
			
			element.on("click",function(){
			
				var target = $(this).data("target");
				var btn = $(this);
				var overlay = "#overlay_menu";
				
				if( $(this).hasClass('open' ) ) {
					btn.removeClass('open' );
					$(overlay + ",.navtop, #" + target).removeClass('open' );
				}else {
					btn.addClass('open' );
					$(overlay + ",.navtop, #" + target).addClass('open' );
				
				}
			});
			

		});
		
			
	};


	$("html" ).on("click","#overlay_menu, .close-side",function(){
				
		$("#overlay_menu,.navtop,.open-side,.side-menu").removeClass('open' );
			
	});
			

})(jQuery);

